import AuthGuard from "@/helpers/AuthGuard";

export default [
  {
    path: "/account",
    beforeEnter: AuthGuard.withCompleteOnboarding,
    meta: {
      title: "account.profile.meta",
      layout: "AuthenticatedLayout",
    },
    children: [
      {
        path: "portfolio",
        name: "Portfolio",
        component: () => import("@/modules/account/portfolio/views/ThePortfolio.vue"),
      },
      {
        path: "portfolio/edit/:id",
        name: "PortfolioEdit",
        component: () => import("@/modules/account/portfolio/views/ThePortfolioEditView.vue"),
      },
    ],
  },
];
