import "./assets/scss/index.scss";

import { createApp, h, provide } from "vue";
import { createPinia } from "pinia";
import * as Sentry from "@sentry/vue";

import App from "./App.vue";
import router from "./router";
import { ApolloClients } from "@vue/apollo-composable";
import authenticatedService from "@/services/AuthenticatedService";
import i18n from "@/plugins/i18n";
import ErrorHandler from "./helpers/ErrorHandler";
import unAuthenticatedService from "./services/UnAuthenticatedService";
import { useMqttStore } from "@/stores/mqtt";
import VueGtm from "vue-gtm";

const app = createApp({
  setup() {
    provide(ApolloClients, {
      default: authenticatedService,
      unAuthenticated: unAuthenticatedService,
    });
  },
  render: () => h(App),
});

const pinia = createPinia();

if (import.meta.env.VITE_APP_SENTRY === "enabled") {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_APP_SENTRY_DSN,
    environment: import.meta.env.VITE_APP_ENVIRONMENT,
    release: `com.topfluenz.web.creator@${import.meta.env.PACKAGE_VERSION}`,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

app.config.errorHandler = (error: unknown, instance, info) => {
  console.log("VueJS error handler", error, instance, info);
  ErrorHandler.handle(error, router);
};

//await initializeGtm();
app.use(pinia);

app.use(VueGtm, {
  id: import.meta.env.VITE_GTM_ID, // Replace with your GTM ID
  defer: false,
  enabled: true,
  debug: false,
});

// Initialize the MQTT store
const mqttStore = useMqttStore();
mqttStore.initializeMqttClient();

app.use(router).use(i18n).mount("#app");
