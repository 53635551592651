<script lang="ts" setup>
import AuthenticatedLayout from "./modules/layouts/AuthenticatedLayout.vue";
import UnauthenticatedLayout from "./modules/layouts/UnauthenticatedLayout.vue";
import { useRoute } from "vue-router";
import { shallowRef, watch } from "vue";
import LoaderTest from "@/modules/deal/components/LoaderTest.vue";
import TheToast from "@/components/core/notification/TheToast.vue";

const route = useRoute();

const layout = shallowRef();
const layouts = { AuthenticatedLayout, UnauthenticatedLayout };

watch(
  () => route,
  (to) => {
    layout.value = Reflect.get(layouts, String(to.meta.layout));
  },
  {
    deep: true,
  }
);
</script>

<template>
  <transition name="fade" mode="out-in">
    <component :is="layout" v-if="layout" />
  </transition>
  <LoaderTest />
  <TheToast />
</template>
