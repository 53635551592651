import {
  ChartPieIcon,
  BriefcaseIcon,
  CheckBadgeIcon,
  CogIcon,
  CurrencyDollarIcon,
  FolderOpenIcon,
  WalletIcon,
  StarIcon,
  GlobeAmericasIcon,
  HomeIcon,
} from "@heroicons/vue/24/outline";
import { useI18n } from "vue-i18n";
import type { NavigationElementDetail } from "@/components/core/navigation/NavigationElement.vue";

export const useNavigationHelper = () => {
  const { t } = useI18n();

  const navigation = [
    {
      name: t("common.sidebar.deals"),
      to: "DealsDiscovery",
      icon: GlobeAmericasIcon,
      current: true,
      type: "link",
    },
    {
      name: t("common.sidebar.dashboard"),
      to: "Dashboard",
      icon: ChartPieIcon,
      current: true,
      type: "link",
    },
    {
      name: t("common.sidebar.home"),
      to: "",
      icon: HomeIcon,
      current: true,
      type: "link",
      children: [
        {
          name: t("common.sidebar.settings"),
          to: "Settings",
          icon: CogIcon,
          current: false,
        },
        {
          name: t("common.sidebar.portfolio"),
          to: "Portfolio",
          icon: BriefcaseIcon,
          current: false,
        },
        {
          name: t("common.sidebar.reviews"),
          to: "ReviewsListing",
          icon: StarIcon,
          current: false,
        },
      ],
    },
    {
      name: t("common.sidebar.balance"),
      to: "",
      icon: FolderOpenIcon,
      current: true,
      type: "link",
      children: [
        {
          name: t("common.sidebar.wallet"),
          to: "Wallet",
          icon: WalletIcon,
          current: false,
        },
      ],
    },
    {
      name: t("common.sidebar.manager"),
      to: "",
      icon: BriefcaseIcon,
      current: true,
      type: "link",
      children: [
        {
          name: t("common.sidebar.certification"),
          to: "Certifications",
          icon: CheckBadgeIcon,
          current: false,
        },
        {
          name: t("common.sidebar.myDeals"),
          to: "DealListing",
          icon: CurrencyDollarIcon,
          current: false,
        },
      ],
    },
  ] as NavigationElementDetail[];

  return {
    navigation,
  };
};
