import useAccountHandler from "@/helpers/useAccountHandler";
import { useGtm } from "vue-gtm";
import { watch } from "vue";
import type { Creator } from "@/types/AccountTypes";

export const initGtm = async () => {
  const { accountInfo, getInfo } = useAccountHandler();
  const gtm = useGtm();

  const sendEvent = async () => {
    const account = (await getInfo(true)) as Creator;
    if (gtm) {
      gtm.trackEvent({
        event: "boot",
        user_id: `CR/${account?.id}`,
        name: `${account?.first_name} ${account?.last_name}`,
        created: account?.created_at,
        email: account?.email,
        custom_attributes: {
          role: "creator",
        },
      });

      // Update Intercom with the new user data
      if (window.Intercom) {
        window.Intercom("update", {
          user_id: `CR/${account?.id}`,
          name: `${account?.first_name} ${account?.last_name}`,
          created: account?.created_at,
          email: account?.email,
          custom_attributes: {
            role: "creator",
          },
        });
      }
    }
  };

  sendEvent();

  watch(
    accountInfo,
    (account) => {
      if (account) {
        //sendEvent();
      }
    },
    { deep: true }
  );
};
