<script lang="ts" setup>
import { computed, type PropType } from "vue";

defineOptions({
  name: "KzAvatar",
});

type AvatarSize = "sm" | "md" | "lg" | "2xl";

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  size: {
    type: String as PropType<AvatarSize>,
    default: "md",
  },
});

const imageUrlPattern = /\.(jpg|jpeg|png|gif|bmp|webp|svg)$/i;

const isImageValid = computed(() => imageUrlPattern.test(props.name));

const displayText = computed(() => {
  return isImageValid.value ? "" : props.name.substring(0, 1).toUpperCase();
});

const sizeClass = computed(() => {
  switch (props.size) {
    case "sm":
      return "h-6 w-6 text-base";
    case "md":
      return "h-8 w-8 text-lg";
    case "lg":
      return "h-12 w-12 text-xl";
    case "2xl":
      return "h-56 w-56 text-xl";
    default:
      return "h-8 w-8 text-lg";
  }
});
</script>

<template>
  <span :class="['avatar', sizeClass]">
    <template v-if="isImageValid">
      <img :src="props.name" alt="Avatar" class="avatar__image" :class="sizeClass" />
    </template>
    <template v-else>
      <span class="avatar__name">
        {{ displayText }}
      </span>
    </template>
  </span>
</template>

<style lang="scss" scoped>
.avatar {
  @apply inline-flex items-center justify-center rounded-full bg-gray-500;

  &__image {
    @apply rounded-full object-cover;
  }

  &__name {
    @apply font-medium leading-none text-white;
  }
}
</style>
