<script lang="ts" setup>
import { computed, ref } from "vue";
import { useRoute } from "vue-router";
import { useWindowSize } from "@vueuse/core";
import { Bars3Icon } from "@heroicons/vue/24/outline";
import NavigationBar from "@/components/core/navigation/NavigationBar.vue";
import NavigationDrawer from "@/components/core/navigation/NavigationDrawer.vue";
import NavigationSidebar from "@/components/core/navigation/NavigationSidebar.vue";

defineOptions({
  name: "AuthenticatedLayout",
});

const route = useRoute();
const { width } = useWindowSize();

const css = computed((): string => {
  return route.meta.css as string;
});

const isSmallScreen = computed(() => width.value <= 1024);
const drawerOpen = ref(false);
const toggleDrawer = () => {
  drawerOpen.value = !drawerOpen.value;
};
</script>

<template>
  <div :class="{ flex: !isSmallScreen }" class="authenticated-layout">
    <NavigationBar v-if="isSmallScreen">
      <template #button>
        <button class="bg-transparent text-black p-2 rounded-full" @click="toggleDrawer">
          <Bars3Icon class="w-6 h-6" />
        </button>
      </template>
    </NavigationBar>
    <NavigationDrawer
      v-if="isSmallScreen"
      :modelValue="drawerOpen"
      @update:modelValue="drawerOpen = $event"
    />
    <NavigationSidebar v-if="!isSmallScreen" />
    <main :class="css" class="authenticated-layout-main">
      <RouterView v-slot="{ Component, route }">
        <transition mode="out-in" name="fade">
          <component :is="Component" :key="route.name" />
        </transition>
      </RouterView>
    </main>
  </div>
</template>

<style lang="scss" scoped>
.authenticated-layout {
  @apply h-screen relative;

  &-main {
    @apply flex-1 p-8 bg-basic-50 overflow-x-auto;

    &.unpadded-layout {
      @apply p-0;
    }
  }
}
</style>
